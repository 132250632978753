import React, { useState, useRef } from 'react';
import './ludoStyle.css'
import diceImage from '../../assets/image/diceFront.png';
import redTokenImage from '../../assets/image/red token.png';
import greenTokenImage from '../../assets/image/green token.png';
import yellowTokenImage from '../../assets/image/yellow token.png';
import blueTokenImage from '../../assets/image/blue token.png';
import clickSoundFile from '../../assets/backgroundMusic/mixkit-classic-click-1117.wav';

const LudoGame = () => {
    const [redPlaying, setRedPlaying] = useState(false);
    const [greenPlaying, setGreenPlaying] = useState(false);
    const [yellowPlaying, setYellowPlaying] = useState(false);
    const [bluePlaying, setBluePlaying] = useState(false);
    const [nPlaying, setNPlaying] = useState(0);

    const menuRef = useRef(null);
    const clickSound = new Audio(clickSoundFile); // Use imported audio file

    const handlePlayerClick = (playerColor) => {
        clickSound.play();

        switch (playerColor) {
            case 'red':
                setRedPlaying(prev => {
                    const newValue = !prev;
                    setNPlaying(nPlaying + (newValue ? 1 : -1));
                    return newValue;
                });
                break;
            case 'green':
                setGreenPlaying(prev => {
                    const newValue = !prev;
                    setNPlaying(nPlaying + (newValue ? 1 : -1));
                    return newValue;
                });
                break;
            case 'yellow':
                setYellowPlaying(prev => {
                    const newValue = !prev;
                    setNPlaying(nPlaying + (newValue ? 1 : -1));
                    return newValue;
                });
                break;
            case 'blue':
                setBluePlaying(prev => {
                    const newValue = !prev;
                    setNPlaying(nPlaying + (newValue ? 1 : -1));
                    return newValue;
                });
                break;
            default:
                break;
        }
    };

    const canPlay = () => nPlaying >= 2;

    const generateUrl = () => {
        // Construct the URL with query parameters
        return `/ludoplay?nPlaying=${encodeURIComponent(nPlaying)}&redPlaying=${encodeURIComponent(redPlaying)}&greenPlaying=${encodeURIComponent(greenPlaying)}&yellowPlaying=${encodeURIComponent(yellowPlaying)}&bluePlaying=${encodeURIComponent(bluePlaying)}`;
    };

    const startGame = () => {
        if (canPlay()) {
            clickSound.play();
            if (menuRef.current) {
                menuRef.current.style.animation = "closing 0.5s linear";
            }
            setTimeout(() => {
                const dynamicUrl = generateUrl();
                window.location.href = dynamicUrl;
            }, 500);
        } else {
            console.log("Select more than 1 player");
        }
    };

    return (
        <div className="menuContainer">
            <div className="container my-auto">
                <div className="row">
                    <div className="col-sm-8 col-md-6 col-lg-5 col-xl-4 startMenu mx-auto text-center" ref={menuRef}>
                        <div className="menu">
                            <h1 className="my-3"><b>LUDO</b></h1>
                            <img className="image_dice" src={diceImage} alt="Dice" />
                            <h3 className="my-3 font-monospace">Select Players</h3>
                            <div className="choosePlayers">
                                <div
                                    className={`redPlayer players ${redPlaying ? 'selected' : ''}`}
                                    onClick={() => handlePlayerClick('red')}
                                >
                                    <img src={redTokenImage} alt="Red Token" />
                                </div>
                                <div
                                    className={`greenPlayer players ${greenPlaying ? 'selected' : ''}`}
                                    onClick={() => handlePlayerClick('green')}
                                >
                                    <img src={greenTokenImage} alt="Green Token" />
                                </div>
                                <div
                                    className={`yellowPlayer players ${yellowPlaying ? 'selected' : ''}`}
                                    onClick={() => handlePlayerClick('yellow')}
                                >
                                    <img src={yellowTokenImage} alt="Yellow Token" />
                                </div>
                                <div
                                    className={`bluePlayer players ${bluePlaying ? 'selected' : ''}`}
                                    onClick={() => handlePlayerClick('blue')}
                                >
                                    <img src={blueTokenImage} alt="Blue Token" />
                                </div>
                            </div>
                            <div className="start">

                                <button className="btn btn-light my-3" onClick={startGame}><b>Play</b></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default LudoGame;
