import React, { useState, useEffect, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col } from "react-bootstrap"; // Import Bootstrap components
import { useSelector, useDispatch } from "react-redux";
import { fetchUserHeader } from "../../redux/actions";
import {
  initializeSocket,
  joinCasinoGame,
  leaveCasinoGame,
} from "../../socket/socket";
import Allcard from "../../Components/AllCard";
import getImagePath from "../../Components/GetFilterCard";

import { AddCasinoBet, getCasinoBets } from "../../services/ApiService";
import { toast } from "react-toastify";
import CasinoActivebets from "../../Components/CasinoActivebets"

const App = () => {
  const [casinobet, setcasinobet] = useState({});
  const [selectedAmount, setSelectedAmount] = useState(0);
  const [data, setData] = useState([]);
  const [lastresult, setlastresult] = useState([]);
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const [ActiveBets, setActiveBets] = useState([]);

  const [Betplaceimage, setBetplaceimage] = useState("");



  const targetRef = useRef(null);


  const id = "dt20";

  const getCasnobetsdata = async () => {
    const response = await getCasinoBets(user, id);
    if (response) {
      setActiveBets(response.data);
    }
  };


  useEffect(() => {
    if (ActiveBets.length > 0) {
      dispatch(fetchUserHeader());
    }
    
  },[ActiveBets])

  const getRealtime = () => {
    if (ActiveBets.length > 0) {
      getCasnobetsdata()
    }
  }

  useEffect(() => {
    const socket = initializeSocket();
    joinCasinoGame(id);
    socket.on("receiveData", (newData) => {
      console.log("7 up data recivedata", newData);

      if (ActiveBets.length > 0) {
        getRealtime()
      }

      setData(newData?.data?.data?.data);
      setlastresult(newData?.data?.data?.result);
    });

    return () => {
      if (socket) {
        socket.emit("leaveCasinoGame", id);
      }
    };
  }, []);



  useEffect(() => {
    getCasnobetsdata();
  }, []);


  useEffect(() => {
    if (targetRef.current) {
      targetRef.current.scrollIntoView({ behavior: "smooth" });
    }
  },[casinobet])

  const handleChange = (e) => {
    const value = e.target.value;
    const numericValue = value === "" ? 0 : Number(value);
    if (numericValue >= 0 && numericValue <= 2500000) {
      setSelectedAmount(numericValue);
    }
  };

  // const handleAmountClick = (amount) => setSelectedAmount(amount);
  const handleClearAmount = () => setSelectedAmount(0);

  const [timers, settimers] = useState(8);
  const [startTimer, setStartTimer] = useState(false);

  const AddCasinoBat = (data) => {
    if (data.gstatus == 0) {
      return;
    }
    handleClearAmount();
    setcasinobet({
      mid: data.t1[0].mid,
      nat: data.nat,
      rate:data.nat === "Tie" ? 0.45 : 0.95,
      gtype: data.t1[0].gtype,
      sid: data.sid,
    });

    settimers(8); // Reset the timer to 8 seconds
    setStartTimer(true); // Start the timer
  };

  useEffect(() => {
    let interval;
    if (startTimer) {
      interval = setInterval(() => {
        settimers((prevtimers) => {
          if (prevtimers <= 1) {
            clearInterval(interval);
            setStartTimer(false); // Stop the timer when it reaches 0
            setcasinobet({});
            handleClearAmount();
            return 0;
          }
          return prevtimers - 1;
        });
      }, 1000);
    }

    // Cleanup interval on component unmount
    return () => {
      clearInterval(interval);
    };
  }, [startTimer]);

  const [wait, setWait] = useState(false);
  useEffect(() => {
    if (Object.keys(casinobet).length > 0) {
      setcasinobet((prev) => ({
        ...prev,
        UserId: user,
        Amount: selectedAmount,
      }));
    }
  }, [selectedAmount]);

  

  // const submitbet = async () => {
  //   if (Object.keys(casinobet).length === 0) {
  //     toast.error("Please select a Bet");
  //     return; // Exit the function to prevent further execution
  //   }
  //   if (selectedAmount <= 0 || selectedAmount < 100) {
  //     toast.error("Please select an amount greater than or equal to 100.");
  //     return; // Exit the function to prevent further execution
  //   }

  //   if (wait) {
  //     toast.warn("A bet is already in progress. Please wait.");
  //     return;
  //   }

  //   // setcasinobet((prev) => ({
  //   //   ...prev,
  //   //   UserId: user,
  //   //   Amount: selectedAmount,
  //   // }));

  //   setWait(true);

  //   if (casinobet.UserId && casinobet.Amount) {
  //     try {
  //       const response = await AddCasinoBet(casinobet);
  //       if (response) {
  //         dispatch(fetchUserHeader());
  //         getCasnobetsdata()
  //         setcasinobet({});
  //         toast.success("Add Bet Suceesfully");
  //       }
  //     } catch (error) {
  //       // Handle error
  //       toast.error(`Error placing bet: ${error.message}`);
  //     } finally {
  //       // Reset the waiting state after the delayx
  //       await new Promise((resolve) => setTimeout(resolve, 8000)); // 8 seconds delay
  //       setWait(false);
  //       setcasinobet({});
  //     }
  //   }
  // };

  const [ismodel, setismodel] = useState(false);
  const [showMessage, setshowMessage] = useState("");

  const submitbet = async () => {

    setismodel(true);

    if (Object.keys(casinobet).length === 0) {
      // toast.error("Please select a Bet");
      setshowMessage("Please select a Bet")
      setBetplaceimage('cross-bet.svg')

      return; // Exit the function to prevent further execution
    }
    if (selectedAmount <= 0 || selectedAmount < 100) {
      setshowMessage("Please select an amount greater than or equal to 100.")
      setBetplaceimage('cross-bet.svg')


      // toast.error();
      return; // Exit the function to prevent further execution
    }

    if (wait) {
      setshowMessage("A bet is already in progress. Please wait.")
      setBetplaceimage('cross-bet.svg')

      // toast.warn("A bet is already in progress. Please wait.");
      return;
    }

    

    setWait(true);

    if (casinobet.UserId && casinobet.Amount) {
      try {
        const response = await AddCasinoBet(casinobet);
        if (response) {
          dispatch(fetchUserHeader());
          getCasnobetsdata()
          setcasinobet({});
          setshowMessage("Add Bet Suceesfully")
          setBetplaceimage('checkpay.svg')

          // toast.success("Add Bet Suceesfully");
        }
      } catch (error) {
        // Handle error
        // toast.error(`Error placing bet: ${error.message}`);
        setshowMessage(`Error placing bet: ${error.message}`)

      } finally {
        // Reset the waiting state after the delayx
        await new Promise((resolve) => setTimeout(resolve, 8000)); // 8 seconds delay
        setWait(false);
        setcasinobet({});
      }
    }
  };

  const bottomAmount = (item) => {
    let Amount = 0.0
    const filteredBets = ActiveBets.filter(bet => bet.nat == item);
    // Calculate the total Amount using reduce
    Amount = filteredBets.reduce((acc, bet) => acc + bet.Amount, 0);

    // Log the filtered bets and total amount
    console.warn("Filtered Bets", filteredBets);
    console.warn("Total Amount", Amount);

    // Return the total amount
    return  Amount <= 0 ? Amount.toFixed(1) : Amount;

  }

  

  return (
    <div className="live-casino-container">
      <Container className="ander_bahar">
        <div className="col-md-8 m-auto user_bk_dd">
          <div className="row">
            <div className="col-6 col-md-6">
              <div className="id_username p-2"> Dragon Tiger </div>
            </div>
            <div className="col-6 col-md-6">
              {data?.t1?.map((item, key) => {
                return (
                  <div className="id_userfac p-2">RoundId: {item.mid}</div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="col-md-8 m-auto">
          <div className="video-containers video_hh">
            <iframe
              src="https://winx777.com/casino/?id=3035"
              width="100%"
              height="100%"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                border: "none",
              }}
            ></iframe>
            {data?.t1?.map((item, key) => {
              return (
                <>
                  <div className="slider_time">
                    <p>{item.autotime}</p>
                  </div>
                </>
              );
            })}
            <div className="slider_cardamar">
              <div className="row justyfy_card">
                <div className="col-md-2">
                  <div>
                    {data?.t1?.map((item, key) => {
                      return (
                        <>
                          <img
                            src={getImagePath(item.C1)}
                            alt="logo"
                            width={25}
                          />
                        </>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-8 m-auto border_carander dt_tiger mt-4">
          <Row>
            {data?.t2?.slice(0, 1).map((item, key) => {
              return (
                <React.Fragment key={key}>
                  <div className="col-4 col-md-4">
                    <div className="redzonesx">
                      <p>0.95</p>
                    </div>
                    <div
                      className="ader_b_box"
                      onClick={() =>
                        AddCasinoBat({
                          t1: data.t1,
                          ...item,
                        })
                      }
                    >
                      <p>{item.nat}</p>
                      {item.gstatus == 0 && (
                        <div className="box-overlay">
                          <span className="icon"></span>
                        </div>
                      )}
                    </div>
                    <div className="redzone">
                    <p>{bottomAmount(item.nat)}</p>
                    </div>
                  </div>
                </React.Fragment>
              );
            })}

            {data?.t2?.slice(2, 3).map((item, key) => {
              return (
                <React.Fragment key={key}>
                  <div className="col-4 col-md-4">
                    <div className="redzonesx">
                      <p>0.45</p>
                    </div>
                    <div
                      className="ader_b_box"
                      onClick={() =>
                        AddCasinoBat({
                          t1: data.t1,
                          ...item,
                        })
                      }
                    >
                      <p>{item.nat}</p>
                      {item.gstatus == 0 && (
                        <div className="box-overlay">
                          <span className="icon"></span>
                        </div>
                      )}
                    </div>
                    <div className="redzone">
                      <p>{bottomAmount(item.nat)}</p>
                    </div>
                  </div>
                </React.Fragment>
              );
            })}
            {data?.t2?.slice(1, 2).map((item, key) => {
              return (
                <React.Fragment key={key}>
                  <div className="col-4 col-md-4">
                    <div className="redzonesx">
                      <p>0.95</p>
                    </div>
                    <div
                      className="ader_b_box"
                      onClick={() =>
                        AddCasinoBat({
                          t1: data.t1,
                          ...item,
                        })
                      }
                    >
                      <p>{item.nat}</p>
                      {item.gstatus == 0 && (
                        <div className="box-overlay">
                          <span className="icon"></span>
                        </div>
                      )}
                    </div>
                    <div className="redzone">
                      <p>{bottomAmount(item.nat)}</p>
                    </div>
                  </div>
                </React.Fragment>
              );
            })}
          </Row>
        </div>

        {
          casinobet.gtype && (
            <div className="col-md-8 m-auto mt-1 mb-4" ref={targetRef}>
          <div className="col-md-12 ">
            <div className="row pt-2">
              <div className="main-price mb-4 mt-1" id="target-element">
                <div className="row justify-content-end mt-1">
                  <div className="col-md-3">
                    {casinobet?.nat ? (
                      <p>
                        {casinobet.gtype} : {casinobet.nat}
                      </p>
                    ) : null}
                  </div>
                  <div className="col-md-2">
                    <p>RATE :{casinobet?.rate ? casinobet.rate : 0}</p>
                  </div>
                  <div className="col-md-7">
                    <div className="card amountt">
                      <div className="card-body">
                        <form className="mt-0">
                          <div className="form-group">
                            <label
                              className="mb-2 text-dark"
                              htmlFor="selectedAmount"
                            >
                              Selected Amount
                            </label>
                            <input
                              type="Number"
                              className="form-control"
                              id="selectedAmount"
                              value={selectedAmount}
                              onChange={handleChange}
                              min={0}
                              max={2500000}
                            />
                          </div>
                          <div className="d-flex justify-content-between mt-4">
                            <button
                              type="button"
                              className="btn btn-secondary"
                              onClick={handleClearAmount}
                            >
                              Clear
                            </button>
                            <div className="bg-danger timeers">{timers}</div>
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={submitbet}
                            >
                              Done
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
          )
        }

        {
        ActiveBets.length > 0 && (
          <CasinoActivebets data={ActiveBets} />
        )
      }

               { ismodel && (
                  <div className="popup-overlay">
                    <div className="popup-content">
                      <img src={`../images/${Betplaceimage}`}></img>
                      <h4>{showMessage}</h4>
                      <button onClick={() => setismodel(false)}>OK</button>
                    </div>
                  </div>
                  )}

        <div className="col-md-8 m-auto border_carander mt-4">
          <Row>
            <div className="col-md-12 bg_rusls">
              <div className="row pt-2">
                <div className="col-6 col-md-6 mb-2">
                  <p className="Result_v">Last Result</p>
                </div>
                <div className="col-6 col-md-6">
                  <p className="statment_v">View All</p>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-3"></div>
                <div className="col-md-9">
                  <ul className="resul_ma9in">
                    {lastresult?.map((item, key) => {
                      return (
                        <li className="result-b">
                          {item.result == 1 ? "D" : "T"}
                        </li>
                      );
                    })}

                    {/* <li className="result-b">b</li> */}
                  </ul>
                </div>
              </div>
            </div>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default App;
