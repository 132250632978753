import React from 'react';
import './Table.css';
import Chip from '../chips/Chips';
import { Overlay, OverlayTrigger, Tooltip } from 'react-bootstrap';
import axios from 'axios';

class RouletteTable extends React.Component {
  state = {
    firstRow: this.props.firstRow,
    firstBorder: this.props.firstBorder,
    secondRow: this.props.secondRow,
    secondBorder: this.props.secondBorder,
    thirdRow: this.props.thirdRow,
    thirdBorder: this.props.thirdBorder,
    fifthRow: this.props.fifthRow,
    columnLeft: this.props.columnLeft,
    disabled: false,
    selectedBetType: null,
    selectedBetValue: null,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.spinning !== this.props.spinning) {
      this.setState({ disabled: this.props.spinning });
    }
  }

  numsSelectionHandler = (num, whichRow) => {
    const allRows = [
      'firstRow',
      'firstBorder',
      'secondRow',
      'secondBorder',
      'thirdRow',
      'thirdBorder',
      'fifthRow',
    ];

    let updatedState = {};

    allRows.forEach((rowKey) => {
      updatedState[rowKey] = this.state[rowKey].map((chip) => ({
        ...chip,
        visible: rowKey === whichRow && chip.n === num,
      }));
    });

    this.setState({ ...updatedState, selectedBetType: 'number', selectedBetValue: num });
    // this.storeBet('number', num);
    console.warn('Bet Type: number, Value:', num);

    this.props.updateRow(whichRow, updatedState[whichRow]);
    this.props.updateArr([num]); // Set the new selection
    // this.props.updateCoins(this.props.coins - this.props.chip);
  };

  storeBet = (type, value) => {
    // axios.post('http://localhost:5000/api/v1/user/ResultRollet', { type, value })
    //   .then(response => {
    //     console.log('Bet stored successfully:', response.data);
    //   })
    //   .catch(error => {
    //     console.error('Error storing bet:', error);
    //   });
  };

  handleOddEvenBet = (type) => {
    const value = type === 'odd' ? 'odd' : 'even';
    this.setState({ selectedBetType: 'odd/even', selectedBetValue: value });
    this.storeBet('odd/even', value);
    console.log(`Bet Type: ${type.toUpperCase()}, Value: ${value}`);
  };

  handleBlackRedBet = (type) => {
    const value = type === 'black' ? 'black' : 'red';
    this.setState({ selectedBetType: 'black/red', selectedBetValue: value });
    this.storeBet('black/red', value);
    console.log(`Bet Type: ${type.toUpperCase()}, Value: ${value}`);
  };

  render() {
    return (
      <React.Fragment>
        <div className="d-flex flex-row align-items-start roulette-table tablee">
          <div className="align-self-start">
            <ul className="list-unstyled">
              {this.state.columnLeft.map((num, index) => (
                <li
                  key={num.n + index}
                  className={num.className + ' no-cursor'}
                  value={num.n}
                >
                  <OverlayTrigger
                    overlay={<Tooltip id="tooltip-disabled">No bets on {num.n}!</Tooltip>}
                  >
                    <span className="d-inline-block">{num.n}</span>
                  </OverlayTrigger>
                </li>
              ))}
            </ul>
          </div>
          <div className="align-self-start">
            <div className="table-divider"></div>
            {[
              'firstRow',
              'firstBorder',
              'secondRow',
              'secondBorder',
              'thirdRow',
              'thirdBorder',
              'fifthRow',
            ].map((rowKey, rowIndex) => (
              <ul key={rowIndex} className="d-flex list-unstyled">
                {this.state[rowKey].map((num, index) => (
                  <button
                    key={num.n + index}
                    className={num.className}
                    value={num.n}
                    onMouseEnter={this.disableTable}
                    disabled={this.state.disabled}
                    onClick={() => this.numsSelectionHandler(num.n, rowKey)}
                  >
                    <Chip id={num.n} active={num.visible} />
                  </button>
                ))}
              </ul>
            ))}
            <div className="table-divide tbl-dividerr"></div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default RouletteTable;