const Allcard = {
        "AHH": "/52-cards/1.png",
        "2HH": "/52-cards/2.png",
        "3HH": "/52-cards/3.png",
        "4HH": "/52-cards/4.png",
        "5HH": "/52-cards/5.png",
        "6HH": "/52-cards/6.png",
        "7HH": "/52-cards/7.png",
        "8HH": "/52-cards/8.png",
        "9HH": "/52-cards/9.png",
        "10HH": "/52-cards/10.png",
        "JHH": "/52-cards/11.png",
        "QHH": "/52-cards/12.png",
        "KHH": "/52-cards/13.png",
        "ACC": "/52-cards/14.png",
        "2CC": "/52-cards/15.png",
        "3CC": "/52-cards/16.png",
        "4CC": "/52-cards/17.png",
        "5CC": "/52-cards/18.png",
        "6CC": "/52-cards/19.png",
        "7CC": "/52-cards/20.png",
        "8CC": "/52-cards/21.png",
        "9CC": "/52-cards/22.png",
        "10CC": "/52-cards/23.png",
        "JCC": "/52-cards/24.png",
        "QCC": "/52-cards/25.png",
        "KCC": "/52-cards/26.png",
        "ASS": "/52-cards/27.png",
        "2SS": "/52-cards/28.png",
        "3SS": "/52-cards/29.png",
        "4SS": "/52-cards/30.png",
        "5SS": "/52-cards/31.png",
        "6SS": "/52-cards/32.png",
        "7SS": "/52-cards/33.png",
        "8SS": "/52-cards/34.png",
        "9SS": "/52-cards/35.png",
        "10SS": "/52-cards/36.png",
        "JSS": "/52-cards/37.png",
        "QSS": "/52-cards/38.png",
        "KSS": "/52-cards/39.png",
        "ADD": "/52-cards/40.png",
        "2DD": "/52-cards/41.png",
        "3DD": "/52-cards/42.png",
        "4DD": "/52-cards/43.png",
        "5DD": "/52-cards/44.png",
        "6DD": "/52-cards/45.png",
        "7DD": "/52-cards/46.png",
        "8DD": "/52-cards/47.png",
        "9DD": "/52-cards/48.png",
        "10DD": "/52-cards/49.png",
        "JDD": "/52-cards/50.png",
        "QDD": "/52-cards/51.png",
        "KDD": "/52-cards/52.png"
    };

export default Allcard