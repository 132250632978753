import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { GrFormPrevious } from "react-icons/gr";
import { MdNavigateNext } from "react-icons/md";
import { useSelector } from 'react-redux';
import { GetLedgerdata } from "../../services/ApiService";
import BackToMenu from "../../Components/BackToMenu";

const AccountStatement = () => {
  const { user } = useSelector((state) => state.auth);
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 2;

  const [data, setdata] = useState([]);

  useEffect(() => {
    (async () => {
      const response = await GetLedgerdata(user);
      if (response) {
        setdata(response.data);
      }
    })();
  }, []);

  const tableData = [];

  const totalPages = Math.ceil(tableData.length / rowsPerPage);

  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = tableData.slice(indexOfFirstRow, indexOfLastRow);

  return (
    <>
      <div className="container myLedger">
        <div className="row mt-5">
          <div className="col">
            <div className="card bg-default shadow">
              <div className="table-responsive">
                <table className="table align-items-center table-dark table-flush">
                  <thead className="thead-dark">
                    <tr>
                      <th scope="col">DESCRIPTION</th>
                      <th scope="col">WON BY</th>
                      <th scope="col">WON</th>
                      <th scope="col">LOST</th>
                      <th scope="col">HISAB</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((row, index) => (
                      <tr key={index}>
                        <th scope="row">
                          <div className="leager media text-left_discrip">
                            <div className="media-body">
                              <span className="mb-0 text-sm">
                                {row.description}
                              </span>
                            </div>
                          </div>
                        </th>
                        <td>
                        <div className="media-body">
                              <span className="mb-0 text-sm">
                              {row.wonby}
                              </span>
                            </div>
                       </td>
                        <td>
                          <span className="badge badge-dot mr-4 won_blue">{
                            row.won
                            }</span>
                        </td>
                        <td>
                          <div className="avatar-group debit_red">
                            <span>{row.lost}</span>
                          </div>
                        </td>
                        <td>
                          <div className="align-items-center">
                          <span className={`mr-2 ${row.balance < 0 ? 'minus_red' : 'plus_blue'}`}>{row.balance}</span>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="card-footer py-4 fixed-footer">
                <nav aria-label="...">
                  <ul className="pagination justify-content-center mb-0">
                    <li
                      className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
                    >
                      <a
                        className="page-link"
                        href="#!"
                        tabIndex="-1"
                        onClick={() => handlePageChange(currentPage - 1)}
                      >
                        <GrFormPrevious />
                        <span className="sr-only">Previous</span>
                      </a>
                    </li>
                    {[...Array(totalPages)].map((_, index) => (
                      <li
                        className={`page-item ${currentPage === index + 1 ? "active" : ""}`}
                        key={index}
                      >
                        <a
                          className="page-link"
                          href="#!"
                          onClick={() => handlePageChange(index + 1)}
                        >
                          {index + 1}
                        </a>
                      </li>
                    ))}
                    <li
                      className={`page-item ${currentPage === totalPages ? "disabled" : ""}`}
                    >
                      <a
                        className="page-link"
                        href="#!"
                        onClick={() => handlePageChange(currentPage + 1)}
                      >
                        <MdNavigateNext />
                        <span className="sr-only">Next</span>
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>

        <BackToMenu />
      </div>
    </>
  );
};

export default AccountStatement;
