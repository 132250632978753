import React, { useEffect,useState } from "react";
import "./HeaderStyle.css";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserHeader, logout } from '../redux/actions';
import { GetHeaderProfile } from "../services/ApiService";
import { disconnectSocket, initializeSocket } from "../socket/socket";
import { toast, ToastContainer } from "react-toastify";


const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, userheader } = useSelector((state) => state.auth);

  const HandleLogOut = () => {
    
    dispatch(logout());
    disconnectSocket()
    navigate("/login");
  }

  useEffect(() => {
    dispatch(fetchUserHeader())
  },[]) 

  return (
    <>
    <ToastContainer />
      <section className="header">
        <div className="container bg_new">
          <div className="row justify-content-center">
            <div className="col-6 col-sm-6 col-md-6 col-lg-6">
              <div className="logo">
                <Link to="/">
                  <img src="/image/logonew.png" alt="logo" />
                </Link>
              </div>
            </div>
            <div className="col-6 col-sm-6 col-md-6 col-lg-6">
              <div className="logoout">
                <button onClick={HandleLogOut}>
                <div className="d-flex login_acces">
                   <img src="/image/login.svg"></img>
                   <p> Logout </p>
                </div>
             
               </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="marquee text-color-changing">
            <p>Welcome To Bro999 Gaming Website</p>
        </div>
      <section className="nems_ff">
        <div className="container mobile_bb">
          <div className="row">
            <div className="col-lg-12">
              <div className="profilenmae">
                <Link className="text-white" to={`/profile`}>
                  <div className="name-tx ">
                    <span><b>Name: {userheader?.name} </b></span>
                  </div>
                </Link>
                <div className="name-tx">
                  <span>
                    <b>Chips:  {userheader?.coins} </b>
                  </span>
                </div>
                <div className="name-tx">
                  <span>
                    <b>Exposure: {userheader?.explore}</b>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Header;
