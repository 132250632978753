// src/components/ProtectedRoutes.js
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import { initializeSocket } from '../socket/socket';

const ProtectedRoutes = () => {
  const {isAuthenticated} = useSelector((state) => state.auth);
  useEffect(() => {
    if (isAuthenticated) {
      
      // Initialize the WebSocket connection
      const socket = initializeSocket();

      // Optional: Clean-up function to close the socket when the component unmounts
      return () => {
        if (socket && typeof socket.disconnect === 'function') {
          socket.disconnect();
        }
      };
    }
  }, [isAuthenticated]);

  return isAuthenticated ? <Outlet /> : <Navigate to="/login" />;
};

export default ProtectedRoutes;
