import React from "react";

const CasinoActivebets = ({ data }) => {
  return (
    <div className="col-md-8 m-auto border_carander mt-3">
      <div className="row mt-5 mb-4">
        <div className="col p-0">
          <div className="card bg-default shadow">
            <div className="table-responsive">
              <div className="text-center text-white">Match Bets</div>
              <table className="table align-items-center table-dark table-flush">
                <thead className="thead-dark">
                  <tr>
                    <th scope="col">gtype </th>
                    <th scope="col">Mode</th>
                    <th scope="col">RATE</th>
                    <th scope="col">AMOUNT</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((item, index) => (
                    <tr key={index}>
                      <td>{item.gtype}</td>
                      <td>{item.nat}</td>
                      <td>{item.rate}</td>
                      <td>{item.Amount}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CasinoActivebets;
