import React, { useEffect, useState, useRef } from "react";
import "../statement/statementStyle.css";
import BackToMenu from "../../Components/BackToMenu";
import { useSelector, useDispatch } from "react-redux";
import { fetchUserHeader } from "../../redux/actions";
import { initializeSocket, joinMatch } from "../../socket/socket";
import { useParams } from "react-router-dom";
import { debounce } from "lodash";
import {
  AddBet,
  AddBookMakerBets,
  getBets,
  getBookMakerAmount,
  getBookMkerBets,
  getBookMkercompleteBets,
  GetCompleteBets,
  GetMatchDetails,
} from "../../services/ApiService";
import { toast } from "react-toastify";
import { formatDateByTimeInMatch } from "../../Components/FormateDate";

const AccountStatement = () => {
  const [loading, setLoading] = useState(false); // State to track loading status

  const targetRef = useRef(null);

  const params = useParams();
  const dispatch = useDispatch();
  const { id } = params;
  const { user } = useSelector((state) => state.auth);
  const [session_odds, setsession_odds] = useState([]);
  const [matchOdds, SetmatchOdds] = useState([]);
  const [Cricketdata, setCricketdata] = useState({});
  const [betsData, setBetsData] = useState([]);
  const [BookMakerbetsData, setBookMakerBetsData] = useState([]);
  const [BookMakerbetaAmounts, setBookMakerbetaAmounts] = useState({});

  const [Betplaceimage, setBetplaceimage] = useState("");

  const [completebetsData, setcompleteBetsData] = useState([]);
  const [selectedAmount, setSelectedAmount] = useState(0);
  const [selectedBet, setSelectedBet] = useState({});

  const [Matchdetails, setMatchdetails] = useState({});

  const GetMatchDetailsForTimeAndName = async () => {
    const response = await GetMatchDetails(id);
    console.warn(response);
    if (response) {
      setMatchdetails(response.data);
    }
  };

  const getCompletebetsdata = async () => {
    try {
      const Bets = await GetCompleteBets(user, id);
      const BookmaerBets = await getBookMkercompleteBets(user, id);
      setcompleteBetsData(Bets.data);
    } catch (error) {
      console.error(error);
    }
  };
  const getActivebetsdata = async () => {
    try {
      const Bets = await getBets(user, id);
      setBetsData(Bets.data);
    } catch (error) {
      console.error(error);
    }
  };
  const getActiveBooKmakerAmount = async () => {
    try {
      const Bets = await getBookMakerAmount(user, id);
      // console.warn(Bets)
      setBookMakerbetaAmounts(Bets.match);
    } catch (error) {
      console.error(error);
    }
  };

  const getActiveBooKmakerbetsdata = async () => {
    try {
      const Bets = await getBookMkerBets(user, id);
      getActiveBooKmakerAmount();
      setBookMakerBetsData(Bets.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const socket = initializeSocket();
    joinMatch(id);
    socket.on("receiveData", (newData) => {
      console.log("recivedata", newData);
      const APIdata = newData.fetchedData;
      const parsedData = APIdata.map((item) =>
        typeof item === "string" ? JSON.parse(item) : item
      );
      const scorecboardData = newData.scorecboardData;
      const APIMatchBookmaker = newData.MatchOdds;

      if (scorecboardData) {
        // setLoading(false);
        dispatch(fetchUserHeader());
        // getActiveBooKmakerbetsdata();
        getActivebetsdata();

        getCompletebetsdata();
      }
      const BookmakerparsedData = APIMatchBookmaker.map((item) => {
        return typeof item === "string" ? JSON.parse(item) : item;
      });
      SetmatchOdds(BookmakerparsedData);

      const modifiedData = parsedData.filter(
        (item) => item.gtype === "session"
      );

      setsession_odds(modifiedData);
      setCricketdata(scorecboardData.data);
    });

    return () => {
      if (socket) {
        socket.emit("leaveMatch", id);
      }
    };
  }, [id]);

  useEffect(() => {
    getActiveBooKmakerAmount();
    getActiveBooKmakerbetsdata();
    GetMatchDetailsForTimeAndName();
  }, []);

  // const [showPopup, setShowPopup] = useState(false);

  const handleClearAmount = () => setSelectedAmount(0);

  const handleChange = (e) => {
    const value = e.target.value;
    const numericValue = value === "" ? 0 : Number(value);
    if (numericValue >= 0 && numericValue <= 2500000) {
      setSelectedAmount(numericValue);
    }
  };

  const [timers, settimers] = useState(0);
  const [startTimer, setStartTimer] = useState(false);

  useEffect(() => {
    let interval;

    if (startTimer) {
      interval = setInterval(() => {
        settimers((prevtimers) => {
          if (prevtimers <= 1) {
            clearInterval(interval);
            setStartTimer(false); // Stop the timer when it reaches 0
            setSelectedBet({});
            handleClearAmount();

            return 0;
          }
          return prevtimers - 1;
        });
      }, 1000);
    }

    // Cleanup interval on component unmount
    return () => {
      clearInterval(interval);
    };
  }, [startTimer]);

  useEffect(() => {
    if (Object.keys(selectedBet).length > 0) {
      setSelectedBet((prev) => ({
        ...prev,
        matchId: id,
        UserId: user,
        Amount: selectedAmount,
      }));
      getActiveBooKmakerAmount();
    }
  }, [selectedAmount]);

  const AddBets = (data) => {
    const debouncedAddBets = debounce((data) => {
      if (data.RUN <= 0) {
        return;
      }

      handleClearAmount();
      setSelectedBet(data);
      console.warn("bets data", selectedBet);

      settimers(8); // Reset the timer to 8 seconds
      setStartTimer(true); // Start the timer
      if (targetRef.current) {
        targetRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }, 100); // Adjust the delay as needed
    debouncedAddBets(data);
  };

  const [wait, setWait] = useState(false);

  const [ismodel, setismodel] = useState(false);
  const [showMessage, setshowMessage] = useState("");

  const filtermatchchanged = async (gtype) => {
    
    if (selectedBet?.gtype === "session") {
      console.log("Session", selectedBet);
      
      const newfilterdata = session_odds?.filter(row => 
        row.RunnerName === selectedBet.RunnerName
      );


      if (newfilterdata.length > 0) {
        if (selectedBet.Mode === 'NOT') {
          if (newfilterdata[0]?.LaySize1 != selectedBet.Rate || newfilterdata[0].LayPrice1 != selectedBet.RUN) {
            setshowMessage("Match Odds Changes.");
            setBetplaceimage("cross-bet.svg");
            return false
          }
          else{
            return true
          }
        }
        else{
          if (newfilterdata[0]?.BackSize1 != selectedBet.Rate || newfilterdata[0]?.BackPrice1 != selectedBet.RUN) {
            setshowMessage("Match Odds Changes.");
            setBetplaceimage("cross-bet.svg");
            return false
          }
          else{
            return true
          }
        }
      }
      else{
        setshowMessage("Match Odds Changes.");
        setBetplaceimage("cross-bet.svg");
        return false
      }
    } else {
      return true

    }    
  }

  const submitbet = async () => {
    setLoading(true);

    // Simulate a loading process (you can replace this with your own logic)
    // setTimeout(() => {
      
    // }, 4000); // 2 seconds delay

    await new Promise((resolve) => setTimeout(resolve, 4000));
    setLoading(false);
    await new Promise((resolve) => setTimeout(resolve, 1000));
    setismodel(true);
    await new Promise((resolve) => setTimeout(resolve, 1000));


    // if (loading === false) {
    //   setismodel(true);
    // }

    if (Object.keys(selectedBet).length === 0) {
      // toast.error("Please select a Bet");
      setshowMessage("Please select a Bet");
      setBetplaceimage("cross-bet.svg");
      return; // Exit the function to prevent further execution
    }
    if (selectedAmount <= 0 || selectedAmount < 100) {
      // toast.error("Please select an amount greater than or equal to 100.");
      setshowMessage("Please select an amount greater than or equal to 100.");
      setBetplaceimage("cross-bet.svg");
      return; // Exit the function to prevent further execution
    }

    // If there's already a bet in progress, show a warning and exit
    if (wait) {
      // toast.warn("A bet is already in progress. Please wait.");
      setshowMessage("A bet is already in progress. Please wait.");
      setBetplaceimage("cross-bet.svg");

      return;
    }

    // Set the waiting state to true
    setWait(true);


    if (selectedBet.UserId && selectedBet.Amount) {
      // const ischangeodds = await filtermatchchanged(selectedBet.gtype)
      console.warn("new changes")
      try {
        if (selectedBet?.gtype === "session") {
          console.log("Session", selectedBet);

          const ischangeodds = await filtermatchchanged(selectedBet.gtype)
          console.warn("new changes",ischangeodds)
          if (ischangeodds) {
            const response = await AddBet(selectedBet);
            if (response) {
              dispatch(fetchUserHeader());
              setTimeout(() => {
                getActivebetsdata();
              }, 7000);
              
              setSelectedBet({});
              setshowMessage("Add Bet Suceesfully");
              setBetplaceimage("checkpay.svg");
            // toast.success("Add Bet Suceesfully");
            }
          }
          
        } else {
          console.log("Bookmaker", selectedBet);
          const response = await AddBookMakerBets(selectedBet);
          if (response) {
            dispatch(fetchUserHeader());
            setTimeout(() => {
              getActiveBooKmakerbetsdata();
            }, 5000);
            setSelectedBet({});
            setshowMessage("Add Bet Suceesfully");
            setBetplaceimage("checkpay.svg");

            // toast.success("Add Bet Suceesfully");
          }
        }
      } catch (error) {
        // Handle error
        // if (condition) {
          setshowMessage("Insuffucient Balance")
          setBetplaceimage('cross-bet.svg')

        // }
        // toast.error(`Error placing bet: ${error.message}`);
        // setshowMessage(`Error placing bet: ${error.message}`)

      } finally {
        // Reset the waiting state after the delay
        await new Promise((resolve) => setTimeout(resolve, 8000)); // 8 seconds delay
        setWait(false);
        setSelectedBet({});
      }
    }
  };

  // Function to close the popup
  const handleClosePopup = () => {
    setismodel(false);
  };

  // const togglePopup = () => setShowPopup(!showPopup);

  // const tooltip = (
  //   <Tooltip id="tooltip-top">
  //     <p>MAX Bet: 5000 </p>
  //     <p>MAX Market: 600</p>
  //   </Tooltip>
  // );

  // if (loading) {
  //   return <div className="loader"></div>;
  // }

  const TotalCompletebets = () => {
    let totalplus = 0;

    completebetsData.forEach((bet) => {
      totalplus += bet.Proffit_Loose_Amount;
    });

    return totalplus;
  };

  return (
    <>
      <div className="container">
        <div className="rows">
          <div className="col-md-12">
            <div>
              <div className="game-header">
                <span>{Matchdetails?.[0]?.name}</span>
                <span className="float-right">
                  {formatDateByTimeInMatch(Matchdetails?.[0]?.openDate)}
                </span>
              </div>

              <div className="scorecard desktop_view">
                <div className="row">
                  <div className="col-12 col-md-6">
                    <p className="team-1 row mt-2">
                      <span className="team-name col-3">
                        {Cricketdata?.teams?.[0]?.team_short_name ?? "N/A"}
                      </span>
                      <span className="score col-4 text-end">
                        {Cricketdata?.teams?.[0]?.score ?? "N/A"}
                      </span>
                    </p>
                    <p className="team-1 row">
                      <span className="team-name col-3">
                        {Cricketdata?.teams?.[1]?.team_short_name ?? "N/A"}
                      </span>
                      <span className="score col-4 text-end">
                        {Cricketdata?.teams?.[1]?.score ?? "N/A"}
                      </span>
                      <span className="team-name col-5">
                        <span>CRR {Cricketdata?.currentRunRate}</span>
                      </span>
                    </p>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="row">
                      <div className="col-12">
                        <div className="text-xl-end">
                          <span>{Cricketdata?.msg}</span>
                        </div>
                        <div className="row">
                          <div className="col-12">
                            <p className="text-xl-end ball-by-ball mt-2">
                              {Cricketdata?.last24ballsNew
                                ?.slice(0, 6)
                                .map((item, key) => (
                                  <span key={key} className="ball-runs run">
                                    {item.score_card}
                                  </span>
                                ))}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="scorecard mobile_view">
                <div className="scorecard-row-box">
                  <div className="row">
                    <div className="col-6">
                      <p className="team-1">
                        <span className="team-name">
                          {Cricketdata?.teams?.[0]?.team_short_name ?? "N/A"}
                        </span>
                        <span className="text-end">
                          {Cricketdata?.teams?.[0]?.score ?? "N/A"}
                        </span>
                      </p>
                    </div>
                    <div className="col-6 text-end">
                      <p className="team-1">
                        <span className="team-name col-3">
                          {Cricketdata?.teams?.[1]?.team_short_name ?? "N/A"}
                        </span>
                        <span className="score col-4 text-end">
                          {Cricketdata?.teams?.[1]?.score ?? "N/A"}
                        </span>
                      </p>
                    </div>
                    <div className="col-12">
                      <div>
                        <span></span>
                      </div>
                    </div>
                    <div className="col-4">
                      <p className="team-1">
                        <span className="team-name col-5">
                          <span>CRR {Cricketdata?.currentRunRate}</span>
                        </span>
                      </p>
                    </div>
                    <div className="col-8">
                      <p className="text-end ball-by-ball mt-0">
                        {Cricketdata?.last24ballsNew
                          ?.slice(0, 6)
                          .map((item, key) => (
                            <span key={key} className="ball-runs run">
                              {item.score_card}
                            </span>
                          ))}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container inplymach">
        <div className="rows ">
          <div className="col-md-12">
            <div className="card shadow">
              <div className="table-responsive">
                <table className="table align-items-center table-dark table-flush">
                  <thead className="thead-dark">
                    <tr>
                      <th scope="col">Min: 100 Max: 1,00,000</th>
                      <th scope="col">LAGAI </th>
                      <th scope="col">KHAI </th>
                    </tr>
                  </thead>
                  <tbody>
                    {matchOdds[0]?.runners.map((row, index) => {
                      if (row.runnerName === "The Draw") return null; // Skip rows with "The Draw"

                      return (
                      <tr key={index}>
                        <td className="left_tx">
                          {row.runnerName}
                          <br />
                          <span
                            className={`mr-2 ${BookMakerbetaAmounts[row.runnerName] < 0 ? "minus_red" : "plus_blue"}`}
                          >
                            {BookMakerbetaAmounts[row.runnerName] > 0
                              ? BookMakerbetaAmounts[row.runnerName]
                              : `${BookMakerbetaAmounts?.[row?.runnerName]}.00`}
                          </span>
                        </td> 
                        {row.back[0].price ? (
                          <>
                            <td>
                              <div className="media align-items-center">
                                <div
                                  className="LIVKADD red_tx p-2"
                                  onClick={() =>
                                    AddBets({
                                      marketId: matchOdds[0].marketId,
                                      RunnerName: row.runnerName,
                                      Rate: row.back[0].price,
                                      Mode: "LAGAI",
                                      marketId: matchOdds[0].marketId,
                                      SelectionId: row.selectionId,
                                      gtype: matchOdds[0].mname,
                                    })
                                  }
                                >
                                  {row.back[0].price} <br />
                                  {/* {row.back[0].size} */}
                                </div>{" "}
                              </div>
                            </td>
                            <th scope="row">
                              <div className="media align-items-center d">
                                <div
                                  className="LIVKADD blue_tx p-2"
                                  onClick={() =>
                                    AddBets({
                                      marketId: matchOdds[0].marketId,
                                      RunnerName: row.runnerName,
                                      Rate: row.lay[0].price,
                                      Mode: "KHAI",
                                      SelectionId: row.selectionId,
                                      gtype: matchOdds[0].mname,
                                    })
                                  }
                                >
                                  {row.lay[0].price}
                                  <br />
                                  {/* {row.lay[0].size} */}
                                </div>{" "}
                              </div>
                            </th>
                          </>
                        ) : (
                          <>
                            <td className="sudpend_bb" colSpan={2}>
                              SUSPENDED
                            </td>
                          </>
                        )}
                      </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-5">
          <div className="col-lg-12 pdg">
            <div className="card bg-default shadow">
              <div className="table-responsive">
                <table className="table align-items-center table-dark table-flush">
                  <thead className="thead-dark">
                    <tr>
                      <th scope="col">
                        <a href="/rules">
                          <span className="rulsesmae">𝓲</span>
                        </a>
                      </th>
                      <th scope="col">NOT</th>
                      <th scope="col">YES</th>
                    </tr>
                  </thead>
                  <tbody>
                    {session_odds.map((row, index) => (
                      <tr key={index}>
                        <td className="left_data">
                          <div className="d-flex">
                            <div className="dd"></div>
                            <div className="dd">
                              <div className="tabel_fs">
                                {row.RunnerName} <br />
                                <span>Session Limit : {row.max}</span>
                              </div>
                            </div>
                          </div>
                        </td>
                        {row.LaySize1 ? (
                          <>
                            <th scope="row">
                              <div className="media align-items-center">
                                <div
                                  className="media-body blue_tx"
                                  onClick={() =>
                                    AddBets({
                                      RunnerName: row.RunnerName,
                                      RUN: row.LayPrice1,
                                      Rate: row.LaySize1,
                                      Mode: "NOT",
                                      SelectionId: row.SelectionId,
                                      gtype: row.gtype,
                                    })
                                  }
                                >
                                  <span className="mb-0 text-sm">
                                    {row.LayPrice1}
                                  </span>
                                  <div className="money">{row.LaySize1}</div>
                                </div>
                              </div>
                            </th>
                            <td>
                              <div className="media align-items-center">
                                <div
                                  className="media-body red_tx"
                                  onClick={() =>
                                    AddBets({
                                      RunnerName: row.RunnerName,
                                      RUN: row.BackPrice1,
                                      Rate: row.BackSize1,
                                      Mode: "YES",
                                      SelectionId: row.SelectionId,
                                      gtype: row.gtype,
                                    })
                                  }
                                >
                                  <span className="mb-0 text-sm">
                                    {row.BackPrice1}
                                  </span>
                                  <div className="money">{row.BackSize1}</div>
                                </div>
                              </div>
                            </td>
                          </>
                        ) : (
                          <>
                            <td className="sudpend_bb" colSpan={2}>
                              SUSPENDED
                            </td>
                          </>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div
          className="main-price mb-4 mt-5"
          id="target-element"
          ref={targetRef}
        >
          <div className="row justify-content-end mt-5">
            <div className="col-md-3">
              {selectedBet?.RunnerName ? (
                <p>
                  {selectedBet.gtype} : {selectedBet.RunnerName}
                </p>
              ) : null}
            </div>
            <div className="col-md-4">
              <p>
                RATE : {selectedBet?.gtype === "Bookmaker" && selectedBet.Rate}{" "}
                {selectedBet?.RUN && selectedBet.RUN} (
                {selectedBet?.Mode ? selectedBet.Mode : "NO"})
              </p>
            </div>
            <div className="col-md-5">
              <div className="card amountt">
                <div className="card-body">
                  <form className="mt-0">
                    <div className="form-group">
                      <label
                        className="mb-2 text-dark"
                        htmlFor="selectedAmount"
                      >
                        Selected Amount
                      </label>
                      <input
                        type="Number"
                        className="form-control"
                        id="selectedAmount"
                        value={selectedAmount}
                        onChange={handleChange}
                        min={0}
                        max={2500000}
                      />
                    </div>
                    <div className="d-flex justify-content-between mt-4">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={handleClearAmount}
                      >
                        Clear
                      </button>
                      <div className="bg-danger timeers">{timers}</div>

                      <button
                        type="button"
                        className="btn btn-primary d-flex"
                        // onClick={handleButtonClick}
                        onClick={submitbet}
                      >
                        Done
                        {loading && <div className="loader"></div>}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

        {ismodel && (
          <div className="popup-overlay" style={{ zIndex: 9999 }}>
            <div className="popup-content">
              <img src={`../images/${Betplaceimage}`}></img>
              <h2>{showMessage}</h2>
              <button onClick={handleClosePopup}>OK</button>
            </div>
          </div>
        )}

        {betsData?.length > 0 && (
          <div className="row mt-5 mb-4">
            <div className="col">
              <div className="card bg-default shadow">
                <div className="table-responsive">
                  <div className="text-center text-white">FANCY BETS</div>
                  <table className="table align-items-center table-dark table-flush">
                    <thead className="thead-dark">
                      <tr>
                        <th scope="col">RUNNER </th>
                        {/* <th scope="col">DATE</th> */}
                        <th scope="col">RUN</th>
                        <th scope="col">RATE</th>
                        <th scope="col">AMOUNT</th>
                        <th scope="col">MODE</th>
                      </tr>
                    </thead>
                    <tbody>
                      {betsData.map((bet, index) => (
                        <tr key={index}>
                          <td>{bet.RunnerName}</td>
                          {/* <td>
                            {new Date(bet.createdAt).toLocaleDateString()}
                          </td> */}
                          <td>{bet.RUN}</td>
                          <td>{bet.Rate}</td>
                          <td>{bet.Mode === "NOT" ? bet.BaseAmount : bet.Amount }</td>
                          <td>{bet.Mode}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        )}

        {BookMakerbetsData.length > 0 && (
          <div className="row mt-5 mb-4">
            <div className="col pading_fncyz">
              <div className="card bg-default shadow">
                <div className="table-responsive">
                  <div className="text-center text-white">BookMaker BETS</div>
                  <table className="table align-items-center table-dark table-flush">
                    <thead className="thead-dark">
                      <tr>
                        <th scope="col">RUNNER </th>
                        <th scope="col">DATE</th>
                        <th scope="col">RATE</th>
                        <th scope="col">AMOUNT</th>
                        <th scope="col">MODE</th>
                      </tr>
                    </thead>

                    <tbody>
                      {BookMakerbetsData.map((bet, index) => (
                        <tr key={index}>
                          <td>{bet.RunnerName}</td>
                          <td>
                            {new Date(bet.createdAt).toLocaleDateString()}
                          </td>
                          
                          <td>{bet.Rate}</td>

                          <td>
                            {bet.Mode === "KHAI" ? bet.BaseAmount : bet.Amount}
                          </td>
                          <td>{bet.Mode}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* <div className="row mt-5 mb-4">
          <div className="col-lg-12">
            <div className="card bg-default shadow">
              <div className="table-responsive">
                <div className="text-center text-white">FANCY BETS</div>
                <table className="table align-items-center table-dark table-flush">
                  <thead className="thead-dark">
                    <tr>
                      <th scope="col">Session </th>
                      <th scope="col">RUN</th>
                      <th scope="col">RATE</th>
                      <th scope="col">AMOUNT</th>
                      <th scope="col">MODE</th>
                    </tr>
                  </thead>
                  <tbody>
                    {completebetsData.map((bet, index) => (
                      <tr key={index}>
                        <td>{bet.RunnerName}</td>
                        <td>{bet.RUN}</td>
                        <td>{bet.Rate}</td>
                        <td>{bet.Amount}</td>
                        <td>{bet.Mode}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div> */}

        <div className="row mt-5 mb-4">
          <div className="col-lg-12 pading_fncyz">
            <div className="card bg-default shadow">
              <div className="table-responsive">
                <div className="text-center text-white">
                  COMPLETED FANCY BETS
                </div>
                <table className="table align-items-center table-dark table-flush">
                  <thead className="thead-dark">
                    <tr>
                      <th scope="col">RUNNER </th>
                      {/* <th scope="col">DATE</th> */}
                      <th scope="col">RUN</th>
                      <th scope="col">RATE</th>
                      <th scope="col">RESULT</th>
                      <th scope="col">AMOUNT</th>
                      <th scope="col">MODE</th>
                      <th scope="col">P&L</th>
                    </tr>
                  </thead>
                  <tbody>
                    {completebetsData.map((bet, index) => (
                      <tr key={index}>
                        <td>{bet.RunnerName}</td>
                        {/* <td>{new Date(bet.createdAt).toLocaleDateString()}</td> */}
                        <td>{bet.RUN}</td>
                        <td>{bet.Rate}</td>
                        <td>{bet.result}</td>
                        <td>{bet.Amount}</td>
                        <td>{bet.Mode}</td>
                        <td
                          className={`mr-2 ${bet.Proffit_Loose_Amount < 0 ? "minus_red" : "plus_blue"}`}
                        >
                          {" "}
                          {bet.Proffit_Loose_Amount}{" "}
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <td colSpan={6}>Total Plus Minus</td>
                      <td
                        className={`mr-2 ${TotalCompletebets() < 0 ? "minus_red" : "plus_blue"}`}
                      >
                        {TotalCompletebets()}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          {/* <div className="row mt-5">
            <div className="col text-center">
              <button
                type="button"
                className="btn btn-primary mt-3"
                onClick={togglePopup}
              >
                All Events
              </button>
              {showPopup && (
                <div
                  className="modal"
                  tabIndex="-1"
                  role="dialog"
                  style={{ display: "block" }}
                >
                  <div className="modal-dialog" role="document">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title">All Event</h5>
                      </div>
                      <div className="modal-body">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Event </th>
                              <th>Date</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Data 1</td>
                              <td>Data 2</td>
                            </tr>
                            <tr>
                              <td>Data 3</td>
                              <td>Data 4</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-secondary"
                          onClick={togglePopup}
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div> */}

          <BackToMenu />
        </div>
      </div>
    </>
  );
};

export default AccountStatement;
