
import 'bootstrap/dist/css/bootstrap.min.css';
import "./App.css"; 
import 'react-toastify/dist/ReactToastify.css';
import MainRoutes from './routes/MainRoutes';
function App() {
  return (
   
      <MainRoutes />
    
  );
}

export default App;
