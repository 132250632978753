import React, { useReducer, useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './kuberStyle.css';

export const ACTIONS = {
    ADD_DIGIT: 'add-digit',
    CLEAN: 'clean',
    DELETE_DIGIT: 'delete-digit',
    CHOOSE_OPERATION: 'choose-operation',
    EVALUATE: 'evaluate',
};

function reducer(state, { type, payload }) {
    switch (type) {
        case ACTIONS.ADD_DIGIT:
            if (payload.digit === "0" && state.currentOperand === "0") {
                return state;
            }

            if (payload.digit === "." && state.currentOperand?.includes(".")) {
                return state;
            }

            return {
                ...state,
                currentOperand: `${state.currentOperand || ""}${payload.digit}`,
            };

        case ACTIONS.CLEAN:
            return {};

        case ACTIONS.CHOOSE_OPERATION:
            if (state.currentOperand == null && state.previousOperand == null) {
                return state;
            }

            if (state.previousOperand == null) {
                return {
                    ...state,
                    operation: payload.operation,
                    previousOperand: state.currentOperand,
                    currentOperand: null,
                };
            }

            return {
                ...state,
                previousOperand: evaluate(state),
                operation: payload.operation,
                currentOperand: null,
            };

        case ACTIONS.DELETE_DIGIT:
            if (state.currentOperand == null) return state;

            if (state.currentOperand.length === 1) {
                return { ...state, currentOperand: null };
            }

            return {
                ...state,
                currentOperand: state.currentOperand.slice(0, -1),
            };

        case ACTIONS.EVALUATE:
            return {
                ...state,
                currentOperand: evaluate(state),
                previousOperand: null,
                operation: null,
                overwrite: true,
            };

        default:
            return state;
    }
}

function evaluate({ currentOperand, previousOperand, operation }) {
    const prev = parseFloat(previousOperand);
    const current = parseFloat(currentOperand);

    if (isNaN(prev) || isNaN(current)) return "";

    let computation = "";

    if (operation === "+") {
        computation = prev + current;
    }
    // Implement other operations as needed

    return computation.toString();
}

const INTEGER_FORMATTER = new Intl.NumberFormat("en-us", {
    maximumFractionDigits: 0,
});

function formatOperand(operand) {
    if (operand == null) return;
    const [integer, decimal] = operand.split(".");

    if (decimal == null) return INTEGER_FORMATTER.format(integer);
    return `${INTEGER_FORMATTER.format(integer)}.${decimal}`;
}

function DigitButton({ digit, dispatch }) {
    return (
        <button
            onClick={() => dispatch({ type: ACTIONS.ADD_DIGIT, payload: { digit } })}
        >
            {digit}
        </button>
    );
}

function App() {
    const [{ currentOperand, previousOperand, operation }, dispatch] = useReducer(reducer, {});
    const [num, setNum] = useState("");
    const [amount, setAmount] = useState("");
    const [time, setTime] = useState(new Date());

    useEffect(() => {
        const interval = setInterval(() => {
            setTime(new Date());
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    const handleNumChange = (e) => {
        const value = e.target.value;
        if (/^\d*\.?\d*$/.test(value)) {
            setNum(value);
        }
    };

    const handleAmountChange = (e) => {
        const value = e.target.value;
        if (/^\d*\.?\d*$/.test(value)) {
            setAmount(value);
        }
    };

    const handleClearClick = () => {
        dispatch({ type: ACTIONS.CLEAN });
        setNum("");
        setAmount("");
    };

    const handleOkClick = () => {
        if (num.trim() === "" || amount.trim() === "") {
            return;
        }

        dispatch({ type: ACTIONS.CLEAN });

        dispatch({
            type: ACTIONS.ADD_DIGIT,
            payload: { digit: `${num}` }
        });
        dispatch({
            type: ACTIONS.CHOOSE_OPERATION,
            payload: { operation: "+" }
        });
        dispatch({
            type: ACTIONS.ADD_DIGIT,
            payload: { digit: `${amount}` }
        });
        dispatch({ type: ACTIONS.EVALUATE });
    };

    const [selectedValue, setSelectedValue] = useState('');

    const handleChange = (event) => {
        setSelectedValue(event.target.value);
    };

    return (
        <div className='heading'>




            <div className='row'>
                <div className='col-12 col-md-12'>
                    <div className='date_name'>
                        <ul>
                            <li>Kuber(Open)</li>
                            <li><p>Lock Time: {time.toLocaleTimeString()}</p></li>
                        </ul>
                    </div>
                </div>
                <div className='col-5 col-md-5'>
                    {/* <label htmlFor="dropdown">Please Bets here</label> */}
                    <select id="dropdown" className='form-control mb-2' value={selectedValue} onChange={handleChange}>
                        <option value="">--Select an option--</option>
                        <option value="Single">Single</option>
                        <option value="Jodi">Jodi</option>
                        <option value="Teenpatti">Teenpatti</option>
                    </select>
                </div>
                <div className='col-7 col-md-7'>
                    <p>Selected Value: {selectedValue}</p>
                </div>
            </div>

            <div className='row'>
                <div className='col-4 col-md-4'>
                <div className="output form-control mb-2">
                    <div className="previous-operand">
                        {formatOperand(previousOperand)} {operation}
                    </div>
                    <div className="current-operand">{formatOperand(currentOperand)}</div>
                </div>
                </div>
                <div className='col-4 col-md-4'>
                <input
                    type="number"
                    placeholder="Num"
                    className="form-control mb-2"
                    value={num}
                    onChange={handleNumChange}
                />
                </div>
                <div className='col-4 col-md-4'>
                <input
                    type="number"
                    placeholder="Amount"
                    className="form-control mb-2"
                    value={amount}
                    onChange={handleAmountChange}
                />
                </div>
            </div>

            <div className="calculator container">
              
              
              
                <div className="d-flex flex-wrap cdsd">
                    <DigitButton digit="1" className="button_add" dispatch={dispatch} />
                    <DigitButton digit="2" className="button_add" dispatch={dispatch} />
                    <DigitButton digit="3" className="button_add" dispatch={dispatch} />
                    <DigitButton digit="4" className="button_add" dispatch={dispatch} />
                    <DigitButton digit="5" className="button_add" dispatch={dispatch} />
                    <DigitButton digit="6" className="button_add" dispatch={dispatch} />
                    <DigitButton digit="7" className="button_add" dispatch={dispatch} />
                    <DigitButton digit="8" className="button_add" dispatch={dispatch} />
                    <DigitButton digit="9" className="button_add" dispatch={dispatch} />
                    <DigitButton digit="0" className="button_add" dispatch={dispatch} />
                    <button
                        className="btn btn-warning"
                        onClick={handleClearClick}
                    >
                        Clear
                    </button>
                    <button
                        className="btn btn-success"
                        onClick={handleOkClick}
                    >
                        Ok
                    </button>
                </div>
            </div>
        </div>
    );
}

const MyTable = () => {
    const [showModal, setShowModal] = useState(false);

    const handleShow = () => setShowModal(true);
    const handleClose = () => setShowModal(false);

    return (
        <div className='container kuber'>
            <div className='main_button'>
                <button className="btn btn-primary" onClick={handleShow}>
                    Please Bets Here
                </button>
            </div>

            <div className="table-container">
                <table className="responsive-table">
                    <thead>
                        <tr>
                            <th colSpan={24} className="header-cell">Kuber-Chart</th>
                        </tr>
                        <tr className="date-row">
                            <th>Date</th>
                            <th>Mon</th>
                            <th>Tue</th>
                            <th>Wed</th>
                            <th>Thu</th>
                            <th>Fri</th>
                            <th>Sat</th>
                            <th>Sun</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                15/07/2024
                                <br />
                                to
                                <br />
                                20/07/2024
                            </td>
                            <td className='r'>XXX</td>
                            <td className='r'>XXX</td>
                            <td className='r'>XXX</td>
                            <td className='r'>XXX</td>
                            <td className='r'>XXX</td>
                            <td>XXX</td>
                            <td>XXX</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div className={`modal fade ${showModal ? 'show' : ''}`} style={{ display: showModal ? 'block' : 'none' }}>
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header header_close">
              {/* <h5 className="modal-title">Please Bets Here</h5> */}
              <button type="button" className="close" onClick={handleClose}>
                <span>&times;</span>
              </button>
            </div>
                        <div className="modal-body">
                            <App />
                        </div>
                        {/* <div className="modal-footer">
              <button type="button" className="btn btn-secondary" onClick={handleClose}>Close</button>
            </div> */}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MyTable;
