import React from "react";
import { initializeSocket } from "../../socket/socket";
import "./rouletteStyle.css";
import axios from "axios";
import { Container, Row, Col, Button } from "react-bootstrap";
import { GiDiamonds } from "react-icons/gi";
import firstRow from "../../Components/table/rows/FirstRow.json";
import firstBorder from "../../Components/table/rows/FirstBorder.json";
import secondRow from "../../Components/table/rows/SecondRow.json";
import secondBorder from "../../Components/table/rows/SecondBorder.json";
import thirdRow from "../../Components/table/rows/ThirdRow.json";
import thirdBorder from "../../Components/table/rows/ThirdBorder.json";
import fourthRow from "../../Components/table/rows/FourthRow.json";
import fifthRow from "../../Components/table/rows/FifthRow.json";
import columnLeft from "../../Components/table/rows/ColumnLeft.json";
import columnRight from "../../Components/table/rows/ColumnRight.json";
import Weel from "../../Components/weel/Weel";
import RouletteTable from "../../Components/table/Table";
import CasinoActivebets from "../../Components/CasinoActivebets";
import { getrouletteActiveBets } from "../../services/ApiService";

class App extends React.Component {
  state = {
    num: "", // Winning number
    arr: [], // Array of bets
    count: 0, // Spins count
    wins: 0, // Wins count
    chip: 0, // Chip value
    coins: 0, // Coins count
    losses: 0, // Losses count
    spinning: false, // Is the wheel spinning?
    message: "Put your bets and spin the wheel!", // Message
    extArr: [], // Extended array for winning bets
    // JSON rows
    firstRow: firstRow,
    firstBorder: firstBorder,
    secondRow: secondRow,
    secondBorder: secondBorder,
    thirdRow: thirdRow,
    thirdBorder: thirdBorder,
    fourthRow: fourthRow,
    fifthRow: fifthRow,
    columnLeft: columnLeft,
    columnRight: columnRight,
    even: [], // Initialize as empty arrays or with actual values
    odd: [],
    black: [],
    red: [],
    oneToEighteen: [],
    nineteenToThirtySix: [],
    thirdTwelves: [],
    secondTwelves: [],
    firstTwelves: [],
    twoByOneFirst: [],
    twoByOneSecond: [],
    twoByOneThird: [],
    ActiveBets: [],
  };

  // Async method to fetch active bets
  getActiveBets = async () => {
    // try {
    //   const response = await getrouletteActiveBets();
    //   // Assuming the response is an array of bets
    //   this.setState({ ActiveBets: response.data });
    // } catch (error) {
    //   // Handle any errors that occurred during the fetch
    //   this.setState({ error: error.message, loading: false });
    // }
  };
  

  componentDidMount() {
    this.getActiveBets()
  }

  isSpinning = (isSpinning) => {
    this.setState({ spinning: isSpinning });
  };

  userLost = async () => {
    this.setState(
      {
        message: `No luck this time!`,
        losses: this.state.losses + 1,
      },
      () => {
        this.resetGame();
      }
    );
  };


  userWin = async (multi) => {
    this.setState(
      {
        message: `You win ₹{multi * this.state.chip} coins!`,
        wins: this.state.wins + 1,
        coins: this.state.coins + multi * this.state.chip,
      },
      () => {
        this.resetGame();
      }
    );
  };

  resetGame = () => {
    this.setState({
      arr: [],
      spinning: false,
      num: "",
      extArr: [],
      firstRow: this.state.firstRow.map((num) => ({ ...num, visible: false })),
      firstBorder: this.state.firstBorder.map((num) => ({
        ...num,
        visible: false,
      })),
      secondRow: this.state.secondRow.map((num) => ({
        ...num,
        visible: false,
      })),
      secondBorder: this.state.secondBorder.map((num) => ({
        ...num,
        visible: false,
      })),
      thirdRow: this.state.thirdRow.map((num) => ({ ...num, visible: false })),
      thirdBorder: this.state.thirdBorder.map((num) => ({
        ...num,
        visible: false,
      })),
      fourthRow: this.state.fourthRow.map((num) => ({
        ...num,
        visible: false,
      })),
      fifthRow: this.state.fifthRow.map((num) => ({ ...num, visible: false })),
      columnLeft: this.state.columnLeft.map((num) => ({
        ...num,
        visible: false,
      })),
      columnRight: this.state.columnRight.map((num) => ({
        ...num,
        visible: false,
      })),
    });
  };

  determineValidBets = (length, element, num, multiplier) => {
    if (element.length === length) {
      const filtering = element.filter((isItMyNum) => isItMyNum === num);
      if (filtering.length > 0) {
        this.setState(
          (prevState) => ({
            extArr: [...prevState.extArr, num],
          }),
          () => this.userWin(multiplier)
        );
      }
    }
  };

  determineValidBetsColFive = (
    name,
    element,
    arrName = [],
    num,
    multiplier
  ) => {
    if (element === name) {
      const filtered = arrName.filter((item) => item === num);
      if (filtered.length > 0) {
        this.setState(
          (prevState) => ({
            extArr: [...prevState.extArr, num],
          }),
          () => this.userWin(multiplier)
        );
      }
    }
  };

  updateNum = (num) => {
    this.setState(
      (prevState) => ({
        num,
        count: prevState.count + 1,
      }),
      () => {
        this.state.arr.forEach((item) => {
          if (item === num) {
            this.userWin(35);
          }

          if (Array.isArray(item)) {
            this.determineValidBets(2, item, num, 17);
            this.determineValidBets(3, item, num, 11);
            this.determineValidBets(4, item, num, 8);
            this.determineValidBets(6, item, num, 5);
          } else {
            this.determineValidBetsColFive(
              "Even",
              item,
              this.state.even,
              num,
              1
            );
            this.determineValidBetsColFive("Odd", item, this.state.odd, num, 1);
            this.determineValidBetsColFive(
              "Black",
              item,
              this.state.black,
              num,
              1
            );
            this.determineValidBetsColFive("Red", item, this.state.red, num, 1);
            this.determineValidBetsColFive(
              "1 to 18",
              item,
              this.state.oneToEighteen,
              num,
              1
            );
            this.determineValidBetsColFive(
              "19 to 36",
              item,
              this.state.nineteenToThirtySix,
              num,
              1
            );
            this.determineValidBetsColFive(
              "3rd 12",
              item,
              this.state.thirdTwelves,
              num,
              1
            );
            this.determineValidBetsColFive(
              "2nd 12",
              item,
              this.state.secondTwelves,
              num,
              1
            );
            this.determineValidBetsColFive(
              "1st 12",
              item,
              this.state.firstTwelves,
              num,
              1
            );
            this.determineValidBetsColFive(
              "2:1:1",
              item,
              this.state.twoByOneFirst,
              num,
              2
            );
            this.determineValidBetsColFive(
              "2:1:2",
              item,
              this.state.twoByOneSecond,
              num,
              2
            );
            this.determineValidBetsColFive(
              "2:1:3",
              item,
              this.state.twoByOneThird,
              num,
              2
            );
          }
        });

        if (this.state.extArr.length === 0) {
          this.userLost();
        }
      }
    );
  };

  updateArr = (arr) => {
    this.setState({ arr });
  };

  updateCoins = (coins) => {
    this.setState({ coins });
  };

  updateRow = (row, val) => {
    this.setState({ [row]: val });
  };


  render() {
    return (
      <Container className="">
        <Row className="justify-items-center pt-2 row">
          <Container fluid className="table ggg">
            <Row className="tableee">
              <Col className="col-md-6 col-6 scal_size">
                <RouletteTable
                  // ROWS
                  firstRow={this.state.firstRow}
                  firstBorder={this.state.firstBorder}
                  secondRow={this.state.secondRow}
                  secondBorder={this.state.secondBorder}
                  thirdRow={this.state.thirdRow}
                  thirdBorder={this.state.thirdBorder}
                  fourthRow={this.state.fourthRow}
                  fifthRow={this.state.fifthRow}
                  columnLeft={this.state.columnLeft}
                  columnRight={this.state.columnRight}
                  // END ROWS

                  updateRow={this.updateRow}
                  updateArr={this.updateArr}
                  updateCoins={this.updateCoins}
                  num={this.state.num}
                  arr={this.state.arr}
                  count={this.state.count}
                  coins={this.state.coins}
                  chip={this.state.chip}
                  spinning={this.state.spinning}
                />

                <Row className="bg-red bg-verdict align-items-center tabl">
                  <Col
                    md={4}
                    className="d-flex align-items-center coins-col justify-content-center"
                  >
                    <h4 className="m-0">₹{this.state.coins}</h4>
                  </Col>
                  <Col md={8}>
                    <div className="text-center">
                      <h6 className="text-uppercase">{this.state.message}</h6>
                    </div>
                    <div className="text-center">
                      <div className="divider-line divider-line-center divider-line-linear-gradient w-100 mx-auto my-4">
                        <GiDiamonds className="diamond-line-icon" />
                      </div>
                      <ul className="list-inline">
                        <li className="list-inline-item">
                          Spins: {this.state.count}
                        </li>
                        <li className="list-inline-item">
                          Wins: {this.state.wins}
                        </li>
                        <li className="list-inline-item">
                          Losses: {this.state.losses}
                        </li>
                      </ul>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col className="col-md-6 col-6  align-self-center">
                <Weel
                  ref="weel"
                  isSpinning={this.isSpinning}
                  updateNum={this.updateNum}
                  num={this.state.num}
                  arr={this.state.arr}
                  count={this.state.count}
                />
              </Col>
            </Row>
          </Container>
          <Container fluid className="table ratebetrr">
            <Row>
              <Col className="text-light-gold btnn">
                <div className="bets-container">
                  {/* <p>Your bets: {this.state.arr.join(", ")}</p> */}
                </div>
              </Col>
            </Row>
          </Container>
          <div className="rolletesmoble">
            <div className="main-price mb-4 mt-5 " id="target-element">
              <div className="row justify-content-end mt-5">
                <div className="col-md-3">
                  {this.state.arr.length != 0 ? (
                    <p>Your bets: {this.state.arr.join(",")}</p>
                  ) : null}
                </div>
                <div className="col-md-4">
                  <p>RATE:</p>
                </div>

                <div className="col-md-5">
                  <div className="card amountt">
                    <div className="card-body">
                      <form className="mt-0">
                        <div className="form-group">
                          <label
                            className="mb-2 text-dark"
                            htmlFor="selectedAmount"
                          >
                            Selected Amount
                          </label>
                          <input
                            type="Number"
                            className="form-control"
                            id="selectedAmount"
                            min={0}
                            max={2500000}
                          />
                        </div>

                        <div className="money_adedd">
                          <ul>
                            <li>
                              <span>100</span>
                            </li>
                            <li>
                              <span>200</span>
                            </li>
                            <li>
                              <span>500</span>
                            </li>
                            <li>
                              <span>1k</span>
                            </li>
                          </ul>
                        </div>

                        <div className="d-flex justify-content-between mt-4">
                          <button type="button" className="btn btn-secondary">
                            Clear
                          </button>

                          <button
                            type="button"
                            className="btn btn-primary d-flex"
                          >
                            Done
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <CasinoActivebets data={ActiveBets} /> */}
        </Row>
      </Container>
    );
  }
}

export default App;
