import Login from "../pages/login/Login";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setAuthenticated, userDetails } from "../redux/actions";
import { useEffect, useState } from "react";
import secureLocalStorage from "react-secure-storage";
import Producted from "./ProtectedRoutes";
import PageNotFound from "../pages/PageNotFound";
import Header from "../Components/header";
import Home from "../pages/home/Home";
import InPlay from "../pages/inPlay/in-Play";
import PersonalInfoTable from "../pages/profile/profile";
import Statement from "../pages/statement/statement";
import Games from "../pages/games/games";
import ChangePassword from "../pages/change-password/change-password";
import MyLedger from "../pages/my-ledger/my-ledger";
import Rules from "../pages/rules/rules";
import CompletedGames from "../pages/completed-Games/completed-games";
import Match from "../pages/match/match";
import ManualGames from "../pages/manual-games/manual-games";
import CricketDemo from "../pages/cricket-live/LiveMatch";
import Kuber from "../pages/kuber/kuber";
import Anderbahar from "../pages/andarBahar/andarbahar";
import Aaa from "../pages/aaa/aaa";
import Lucky7b from "../pages/lucky7b/lucky7b";
import Teenpatti20 from "../pages/teenpatti-t20/teenpatti-t20";
import Dt20 from "../pages/dt20/dt20";
import Dt202 from "../pages/dt202/dt202";
import Anderbahar2 from "../pages/anderbahar2/anderbahar2";
import Ludo from "../pages/ludo/ludo";
import Play from "../pages/ludo/ludoplay";
import Roulette from "../pages/roulette/roulette"
 



const MainRoutes = () => {
  
  const dispatch = useDispatch();
  const {isAuthenticated, user} = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(true);

  const checkAuthentication = async () => {
    try {
      const authStatus = await secureLocalStorage.getItem('isAuthenticated');
      dispatch(setAuthenticated(authStatus)); // Convert to boolean
      dispatch(userDetails(await secureLocalStorage.getItem('userDetails')));
    } catch (error) {
      console.error('Error checking authentication:', error);
      dispatch(setAuthenticated(false));
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    checkAuthentication();
  }, []);

  if (loading) {
    return <div>Loading...</div>; // Replace with your actual loading indicator
  }

  return (
    <BrowserRouter>
      {isAuthenticated && <Header />}
      <Routes>
        <Route
          path="/login"
          element={!isAuthenticated ? <Login /> : <Navigate to="/rules" />}
        />
        <Route element={<Producted />}>
          <Route path="/" element={<Home />} />
          <Route path="/in-play" element={<InPlay />} />
          <Route path="/profile" element={<PersonalInfoTable />} />
          <Route path="/statement" element={<Statement />} />
          <Route path="/games" element={<Games />} />
          <Route path="/change-password" element={<ChangePassword />} />
          <Route path="/my-ledger" element={<MyLedger />} />
          <Route path="/rules" element={<Rules />} />
          <Route path="/completed-games" element={<CompletedGames />} />
          <Route path="/match" element={<Match />} />
          <Route path="/manual-games" element={<ManualGames />} />
          <Route path="/Play-Match/:id" element={<CricketDemo />} />
          <Route path="/kuber" element={<Kuber />} /> 
          <Route path="/andarbahar" element={<Anderbahar />} />   
          <Route path="/aaa" element={<Aaa />} /> 
          <Route path="/lucky7b" element={<Lucky7b />} />  
          <Route path="/teenpatti-t20" element={<Teenpatti20 />} />   
          <Route path="/dt20" element={<Dt20 />} />  
          <Route path="/dt202" element={<Dt202 />} />  
          <Route path="/anderbahar2" element={<Anderbahar2 />} />   
          <Route path="/ludo" element={<Ludo />} />   
          <Route path="/ludoplay" element={<Play />} />   
          <Route path="/roulette" element={<Roulette />} />   

        
 
        </Route>
        <Route path="/*" element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default MainRoutes;
