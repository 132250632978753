import React, { useEffect, useState } from 'react';
import './profileStyle.css';
import { GetProfileInfo } from '../../services/ApiService';
import secureLocalStorage from 'react-secure-storage';
import { formatDate } from '../../Components/FormateDate';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import BackToMenu from '../../Components/BackToMenu';

const PersonalInfoTable = () => {
  const [userInfo, setuserInfo] =  useState({})
  const { user } = useSelector((state) => state.auth);


  useEffect(() => {
    (
      async () => {
        try {
          const response = await GetProfileInfo(user)
          setuserInfo(response.data)
        } catch (error) {
          console.log(error)
        }
      }
    )()
  },[])
  console.log(userInfo)

  return (
    <>

    <section className="profiletable">
        <div className="container">
          <table className="table-fill">
            <thead>
              <tr>
                <th colSpan="3">RATE INFORMATION</th>
              </tr>
            </thead>
            <tbody className="table-hover">
              <tr>
                <td>Rate Difference :</td>
                <td>
                    <select className="form-select w-20">
                      <option>0</option>
                      <option>1</option>
                      <option>2</option>
                      <option>3</option>
                      <option>4</option>
                      <option>5</option>
                    </select>
                  </td>
                  <td>
                    <button className="btn btn-primary">Update</button>
                  </td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
      <section>
        <div className="container">
          <table className="table-fill">
            <thead>
              <tr>
                <th colSpan="2">PERSONAL INFORMATION</th>
              </tr>
            </thead>
            <tbody className="table-hover">
              <tr>
                <td>Client Name :</td>
                <td>
                  {
                    userInfo.name
                  }
                </td>
              </tr>
              <tr>
                <td>Client Code :</td>
                <td>{
                    userInfo.name
                  }</td>
              </tr>
              <tr>
                <td>Chips :</td>
                <td>{
                  userInfo.coins
                }</td>
              </tr>
              <tr>
                <td>Date Of Joining :</td>
                <td>{
                  formatDate(userInfo.createdAt)
                }</td>
              </tr>
              <tr>
                <td>Address</td>
                <td>INDIA</td>
              </tr>
            </tbody>
            <thead>
              <tr>
                <th colSpan="2">
                  
                </th>
              </tr>
            </thead>
          </table>
          <BackToMenu />
        </div>
      </section>
    </>
  );
};

export default PersonalInfoTable;