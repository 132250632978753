import React from "react";
import { Link } from "react-router-dom";

const BackToMenu = () => {
  return (
    <div className="mt-4">
      <div className="d-flex justify-content-center bg-danger p-2 rounded-2">
        <Link className="backto " to={"/"}>
          BACK TO MAIN MENU
        </Link>
      </div>
    </div>
  );
};

export default BackToMenu;
