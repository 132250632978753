export const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const options = { 
      // weekday: 'long', 
      // year: 'numeric', 
      month: 'long', 
      day: 'numeric', 
    };
    return date.toLocaleDateString('en-US', options);
  };


export const formatDateByTime = (timestamp) => {

    const date = new Date(timestamp);

    const options = { 
      // weekday: 'long', 
      // year: 'numeric', 
      // month: 'long', 
      // day: 'numeric', 
      hour: '2-digit', 
      minute: '2-digit', 
      // second: '2-digit' 
    };
    return date.toLocaleTimeString('en-US', options);
  };


export const formatDateByTimeInMatch = (timestamp) => {

const date = new Date(timestamp);

const options = { 
  weekday: 'long', 
  year: 'numeric', 
  month: 'long', 
  day: 'numeric', 
  hour: '2-digit', 
  minute: '2-digit', 
  // second: '2-digit' 
};
return date.toLocaleTimeString('en-US', options);
};