import { combineReducers } from 'redux';
import { SET_AUTHENTICATED, LOGOUT,USER_DETAILS, HEADER_PROFILE  } from './actionTypes';

const initialState = {
  isAuthenticated: false,
  user: null,
  userheader: null
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_AUTHENTICATED:
      return { ...state, isAuthenticated: action.payload };
    case LOGOUT:
      return { ...state, isAuthenticated: false, user: null }; // Reset authentication state
    case USER_DETAILS:
      return { ...state, user: action.payload };
    case HEADER_PROFILE:
        return { ...state, userheader: action.payload }; // Update user details
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  auth: authReducer,
});

export default rootReducer;
