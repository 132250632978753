import axios from 'axios';

const baseurl = 'https://api.bro999.in/api/v1/user'
// const baseurl = 'http://localhost:5000/api/v1/user'
//new commit 

export const GetMatch = async () => {
    try {
        const response = await axios.get(`${baseurl}/match`,
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );
        return response.data; 
    } catch (error) {
        console.error(error);
        throw error; 
    }
}


export const GetMatchDetails = async (matchId) => {
    try {
        const response = await axios.get(`${baseurl}/GetMatchListDate/${matchId}`,
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );
        return response.data; 
    } catch (error) {
        console.error(error);
        throw error; 
    }
}

export const AddBet = async (data) => {
    try {
        const response = await axios.post(`${baseurl}/AddBets`, data, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return response.data; 
    } catch (error) {
        console.error(error);
        throw error; 
    }
};


export const AddCasinoBet = async (data) => {
    try {
        const response = await axios.post(`${baseurl}/AddCasinoBets`, data, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return response.data; 
    } catch (error) {
        console.error(error);
        throw error; 
    }
};


export const getBets = async (id,matchId) => {
    try {
        const response = await axios.get(`${baseurl}/getBets/${id}?matchId=${matchId}`, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return response.data; 
    } catch (error) {
        console.error(error);
        throw error; 
    }
};

export const getCasinoBets = async (id,gameId) => {
    try {
        const response = await axios.get(`${baseurl}/CasinoBets/${id}?gtype=${gameId}`, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return response.data; 
    } catch (error) {
        console.error(error);
        throw error; 
    }
};

export const getrouletteActiveBets = async (id) => {
    try {
        const response = await axios.get(`${baseurl}/roulette/${id}`, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return response.data; 
    } catch (error) {
        console.error(error);
        throw error; 
    }
};

export const GetCompleteBets = async (id,matchId) => {
    try {
        const response = await axios.get(`${baseurl}/getcompleteBets/${id}?matchId=${matchId}`, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return response.data; 
    } catch (error) {
        console.error(error);
        throw error; 
    }
};

export const AddBookMakerBets = async (data) => {
    try {
        const response = await axios.post(`${baseurl}/AddBookMakerBets`, data, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return response.data; 
    } catch (error) {
        console.error(error);
        throw error; 
    }
};
export const getBookMkerBets = async (id,matchId) => {
    try {
        const response = await axios.get(`${baseurl}/getBookMkerBets/${id}?matchId=${matchId}`, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return response.data; 
    } catch (error) {
        console.error(error);
        throw error; 
    }
};


export const getBookMakerAmount = async (id,matchId) => {
    try {
        const response = await axios.get(`${baseurl}/BookMakerBetsAmount/${id}?matchId=${matchId}`, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return response.data; 
    } catch (error) {
        console.error(error);
        throw error; 
    }
};
export const getBookMkercompleteBets = async (id,matchId) => {
    try {
        const response = await axios.get(`${baseurl}/getBookMkercompleteBets/${id}?matchId=${matchId}`, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return response.data; 
    } catch (error) {
        console.error(error);
        throw error; 
    }
};





export const GetHeaderProfile = async (id) => {
    try {
        const response = await axios.get(`${baseurl}/userCoins/${id}`);
        return response.data; // Return response data if needed
    } catch (error) {
        console.error(error);
        throw error; // Optionally rethrow the error if you want to handle it elsewhere
    }
}

export const GetProfileInfo = async (id) => {
    try {
        const response = await axios.get(`${baseurl}/userInfo/${id}`);
        return response.data; // Return response data if needed
    } catch (error) {
        console.error(error);
        throw error; // Optionally rethrow the error if you want to handle it elsewhere
    }
}

export const GetStatement = async (id) => {
    try {
        const response = await axios.get(`${baseurl}/getStatementById/${id}`);
        return response.data; // Return response data if needed
    } catch (error) {
        console.error(error);
        throw error; // Optionally rethrow the error if you want to handle it elsewhere
    }
}

export const GetCompleteGames = async (id) => {
    try {
        const response = await axios.get(`${baseurl}/completed-games/${id}`);
        return response.data; // Return response data if needed
    } catch (error) {
        console.error(error);
        throw error; // Optionally rethrow the error if you want to handle it elsewhere
    }
}
export const GetMatchList = async () => {
    try {
        const response = await axios.get(`${baseurl}/MatchList`);
        return response.data; // Return response data if needed
    } catch (error) {
        console.error(error);
        throw error; // Optionally rethrow the error if you want to handle it elsewhere
    }
}

export const GetLedgerdata = async (id) => {
    try {
        const response = await axios.get(`${baseurl}/getLedger/${id}`);
        return response.data; // Return response data if needed
    } catch (error) {
        console.error(error);
        throw error; // Optionally rethrow the error if you want to handle it elsewhere
    }
}

export const LoginUser = async (data) => {
    try {
        const response = await axios.post(`${baseurl}/login`,
            data,
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );
        return response.data; // Return response data if needed
    } catch (error) {
        console.error(error);
        throw error; // Optionally rethrow the error if you want to handle it elsewhere
    }
}


export const betsnumber = async () => {
    try {
        const response = await axios.get(`${baseurl}/RolletgetNumber/777/bharatjagoar`,
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );
        return response.data; // Return response data if needed
    } catch (error) {
        console.error(error);
        throw error; // Optionally rethrow the error if you want to handle it elsewhere
    }
}

export const userLostbet = async () => {
    try {
        const response = await axios.post(`${baseurl}/RolletgetNumber/777/bharatjagoar`,{
            username: "bharatjagoar",
            result: 0
        },
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );
        return response.data; // Return response data if needed
    } catch (error) {
        console.error(error);
        throw error; // Optionally rethrow the error if you want to handle it elsewhere
    }
}


export const userWinbet = async () => {
    try {
        const response = await axios.post(`${baseurl}/RolletgetNumber/777/bharatjagoar`,{
            username: "bharatjagoar",
            result: 1
        },
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );
        return response.data; // Return response data if needed
    } catch (error) {
        console.error(error);
        throw error; // Optionally rethrow the error if you want to handle it elsewhere
    }
}

